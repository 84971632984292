//import logo from './logo.svg';
import './App.css';

// seperate layout for mobile and laptop - responsive
// need to design the popout menus and blah blah

function App() {
  return (
    <div class="snap-y snap-mandatory h-screen w-screen">
        <div class="snap-start bg-white w-screen h-screen flex flex-col items-center justify-center rounded-lg border border-black">
            <div class="font-size:200 font-bold  text-7xl">
                Oliver Clark
            </div>
            <div class="flex">
                <a class="mx-1 text-4xl bg-white hover:bg-gray-100 text-gray-600 font-semibold border border-gray-400 rounded shadow" href="https:linkedin.com/in/oliver-clark-63b711229" target="_blank">
                    linkedIn
                </a>
                <a class="mx-1 text-4xl bg-white hover:bg-gray-100 text-gray-600 font-semibold border border-gray-400 rounded shadow" href="https://codeforces.com/profile/IntermediateP" target="_blank">
                    codeForces
                </a>
                <a class="mx-1 text-4xl bg-white hover:bg-gray-100 text-gray-600 font-semibold border border-gray-400 rounded shadow">
                    GitHub
                </a>
                <a class="text-4xl bg-white hover:bg-gray-100 text-gray-600 font-semibold border border-gray-400 rounded shadow">
                    twitter
                </a>
            </div>
            <div class="opens-cv rounded border border-gray-200 hover:bg-gray-200 bg-white items-center justify-center shadow text-4xl text-gray-600 font-semibold">
                Open CV
            </div>    

        </div>

        <div class="border border-black snap-y snap-mandatory h-screen w-screen flex flex-col items-center justify-center rounded-lg">
            <div class="w- h- bg-gray-200 py-20 px-10 rounded-xl flex flex-col">
                <a class="text-lg text-center pb-8"  href='' target="_blank">    
                     Latest Blog
                </a>
                <a class="text-sm text-center ">
                    Test
                    this is some code that im writing to 
                    blah blah
                    it should load a json file witg my latest post
                </a>

              
                
            </div>

        </div>
    </div>
    
// tod - when hover over name, prompt an about me page
//       when hovering over the github button it avoids being clicked
//       prompt a click here sign, if they click it creates a little 8 bit character
//       


//

    //
    //<div className="App">
    //  <header className="App-header">
    //    <img src={logo} className="App-logo" alt="logo" />
    //    <p>
    //      Edit <code>src/App.js</code> and save to reload.
    //    </p>
    //    <a
    //      className="App-link"
    //      href="https://williamlin.io/"
    //      target="_blank"
    //      rel="noopener noreferrer"
    //    >
    //      Learn React
    //    </a>
    //  </header>
    //</div>
    
  );
}

export default App;
